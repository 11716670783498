<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      do-route
      do-route-filters
      no-meta-check
      resource="users"
      route-base="/settings/users"
      show-search-filter
      sortable
      :buttons="computedButtons"
      :custom-resource="customResource()"
      :headers="headers"
      :resource-query="resourceQuery"
      :show-operations="false"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.settings.users')
    }
  },
  data() {
    return {
      headers: [
        {
          label: this.$t('pages.settings.users.all.headers.firstname'),
          field: 'firstname',
          minWidth: 120,
          formatter: function (row) {
            if (!row) return '-'
            return row.firstname || '-'
          }
        },
        {
          label: this.$t('pages.settings.users.all.headers.lastname'),
          field: 'lastname',
          minWidth: 120,
          formatter: function (row) {
            if (!row) return '-'
            return row.lastname || '-'
          }
        },
        {
          label: this.$t('pages.settings.users.all.headers.email_or_id'),
          minWidth: 180,
          formatter: function (row) {
            if (!row || !row.user || (!row.user.id && !row.user.email)) {
              return '-'
            }

            return row.user.email || row.user.id
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    configurationId() {
      return get(this.clientAccountConfiguration, 'id', null)
    },
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['settings:users:create']
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter(
        (b) =>
          (b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true) &&
          !b.disabled
      )
    },
    resourceQuery() {
      return {
        query: {
          deleted: false
        }
      }
    }
  },
  methods: {
    customResource() {
      return th.users(this.configurationId)
    },
    refresh() {
      this.$router.push({ name: 'settings-users' })
      this.$refs.table.refresh()
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.settings.users.title')
        })
      })
    }
  }
}
</script>
